import React from "react";
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";
import FaqSection from "~sections/utility/Faq/FaqSection";
import HeaderButton from '~sections/agency/Header';
import FooterFive from '~sections/agency/FooterFive'

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnText="Appointments"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}


export default function FaqPage() {
  return (
    <PageWrapper headerConfig={header}>
      <BreadCrumbSection title="FAQs" text="Frequently asked questions about thyroid treatment."/>
      <FaqSection/>
      <FooterFive/>
    </PageWrapper>
  )
}
